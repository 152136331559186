<template>
    <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
        <div style="display: flex;justify-content: space-between;">
            <div class="tag-container">
                <div v-for="(type, index) in allTypes" :key="index" @click="handleSelectTypeChanged(type)">
                    <p :class="selectedType == type ? 'tag-item-selected tag-item-normal' : 'tag-item-normal'">{{ type
                        }}
                    </p>
                </div>
            </div>
            <el-input v-model="key_tag" style="width: 320px;" @input="handleSearch" placeholder="Search"></el-input>
        </div>

        <div style="margin-top: 20px;">
            <div v-for="(pass, index) in dataList" :key="index" class="pass-item" @click="showPassDetail(pass._id.$id)">
                <img :src="getTypeIcon(pass.type)" class="type_logo">
                <div class="item-second">
                    <p class="pass_name">{{ pass.name }}</p>
<!--                    <p class="pass_tag">{{ pass.tags }}</p>-->
                    <div class="tag-list" v-if="pass.tags && pass.tags.length > 0">
                      <p class="product_tag" v-for="(tag, index) in pass.tags.split(',')" :key="index">{{ tag }}
                      </p>
                    </div>
                </div>
                <div class="pass_ddl">
                    <div class="pass_ddl" style="flex-direction: column; align-items: flex-start;">
                        <div class="purchase-status" style="margin-bottom: 10px;">
                            <span v-if="isPurchaseAvailable(pass.deadline_date)" class="available">Available for Purchase</span>
                            <span v-else class="closed">Closed for Purchase</span>
                        </div>
                        <p v-if="pass.expired_type == 'expired_by_days'">Valid for {{ pass.expired_value }} days<br>after purchase</p>
                        <p v-else>Valid until {{ $dayjs(pass.expired_value).format("MMM DD, YYYY") }}</p>
                    </div>
<!--                    <img src="../../assets/image/icon_time1.png" style="width: 16px;height: 16px;margin-right: 5px;">-->
                    
                </div>
                <div class="pass_intro">
                    {{ pass.short_intro }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
    getOpenPassList
} from '../../api/eae'
import '../../assets/common/common.css'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            //, 'Select N', 'Repeat N'
            dataList: [],
            allTypes: ['All', 'Single Entry', 'Multiple Entry', 'Thematic Bundle', 'Unlimited Pass', 'SelectN'],
            selectedType: 'All',
            key_tag: ''
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        getTypeIcon(type) {
            if (type == 'SingleRide') {
                return require('../../assets/image/pass_type_SingleRide.jpg')
            }
            if (type == 'MultipleRide') {
                return require('../../assets/image/pass_type_MultipleRide.jpg')
            }
            if (type == 'Bundle') {
                return require('../../assets/image/pass_type_Bundle.jpg')
            }
            if (type == 'UnlimitedPass') {
                return require('../../assets/image/pass_type_up.jpg')
            }
            if (type == 'SelectN') {
                return require('../../assets/image/pass_type_SelectN.jpg')
            }
            return ''
        },

        handleSearch(value) {
            if (value) {
                this.key_tag = value
                this.selectedType = ''
                this.fetchData()
            }
        },

        handleSelectTypeChanged(type) {
            this.selectedType = type
            this.key_tag = ''
            this.fetchData()
        },

        showPassDetail(id) {
            this.$router.push({
                path: '/home/pass/detail',
                query: {
                    pass_id: id
                }
            })
        },

        fetchData() {
            let loading = this.$loading({
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let type = this.selectedType
            if (this.selectedType == 'All') {
                type = ''
            }
            getOpenPassList(this.key_tag, type).then((res) => {
                this.dataList = res.data
                loading.close();
            })
        },
        isPurchaseAvailable(deadline) {
            if (!deadline) return true;
      
            const deadlineDate = new Date(parseInt(deadline));
            const currentDate = new Date();
            
            return currentDate < deadlineDate;
        },
    }

})
</script>
<style scoped lang="scss">
/deep/ .el-input__inner {
    border-radius: 5px;
    background-color: #f5f5f5;
}

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .tag-item-normal {
        padding: 0px 6px;
        min-width: 85px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        background-color: #f5f5f5;
        color: #999;
        font-size: 14px;
        font-family: centurygothic_bold;
        cursor: pointer;
        border: 1px solid white;
    }

    .tag-item-selected {
        background-color: #FFF8F7;
        color: var(--color-primary);
        border: 1px solid #FF6450;
    }
}

.pass-item {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #E6D7D7;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;

    .type_logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }

    .item-second {
        padding: 0px 15px;
        box-sizing: border-box;
        flex: 2;

        .pass_name {
            color: #333;
            font-size: 14px;
            font-family: centurygothic_bold;
        }

        .pass_tag {
            margin-top: 10px;
            color: #4A0700;
            font-size: 14px;
            line-height: 22px;
            font-family: centurygothic;
        }
        .tag-list{
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          margin-top: 8px;
        }
        .product_tag {
          padding: 2px 4px;
          display: inline-block;
          background-color: #FFF2CC;
          box-sizing: border-box;
          color: #333;
          font-size: 12px;
          line-height: 22px;
          font-family: centurygothic;
        }
    }


    .pass_ddl {
        flex: 1;
        display: flex;
        align-items: center;
        width: 200px;
        color: #FC6556;
        font-size: 14px;
        font-family: centurygothic_bold;
    }

    .pass_intro {
        flex: 3;
        height: 100%;
        color: #666;
        font-size: 12px;
        line-height: 20px;
        font-family: centurygothic;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
    }

    .closed {
        display: inline-block;
        padding: 5px 10px;
        background-color: #F2F2F2;
        color: #999999;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: not-allowed;
    }
    .available {
        display: inline-block;
        padding: 5px 10px;
        background-color: #E6F7FF;
        color: #1890FF;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }
}
</style>